<template>
  <svg
    width="425"
    height="456"
    viewBox="0 0 400 300"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="group"
  >
    <g :class="props.backgroundClass" filter="url(#filter0_dddddd_5626_22391)">
      <path
        d="M70 57.1777C70 25.6975 95.5198 0.177734 127 0.177734H288C319.48 0.177734 345 25.6975 345 57.1777V218.178C345 249.658 319.48 275.178 288 275.178H127C95.5198 275.178 70 249.658 70 218.178V57.1777Z"
        fill="currentColor"
      />
      <path
        d="M70.5 57.1777C70.5 25.9736 95.7959 0.677734 127 0.677734H288C319.204 0.677734 344.5 25.9736 344.5 57.1777V218.178C344.5 249.382 319.204 274.678 288 274.678H127C95.7959 274.678 70.5 249.382 70.5 218.178V57.1777Z"
        stroke="white"
        stroke-opacity="0.4"
      />
    </g>
    <g
      :class="props.iconClass"
      clip-path="url(#clip0_5626_22391)"
      filter="url(#filter1_i_5626_22391)"
    >
      <path
        d="M240.791 147.538L244.853 121.083H219.467V103.915C219.467 96.6794 223.012 89.6214 234.382 89.6214H245.922V67.0999C245.922 67.0999 235.451 65.3137 225.438 65.3137C204.533 65.3137 190.87 77.9861 190.87 100.923V121.088H167.631V147.542H190.87V211.498H219.467V147.542L240.791 147.538Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <filter
        id="filter0_dddddd_5626_22391"
        x="-10"
        y="0.177734"
        width="435"
        height="455"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2.76726" />
        <feGaussianBlur stdDeviation="1.1069" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0196802 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5626_22391"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="6.6501" />
        <feGaussianBlur stdDeviation="2.66004" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0282725 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_5626_22391"
          result="effect2_dropShadow_5626_22391"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="12.5216" />
        <feGaussianBlur stdDeviation="5.00862" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.035 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_dropShadow_5626_22391"
          result="effect3_dropShadow_5626_22391"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="22.3363" />
        <feGaussianBlur stdDeviation="8.93452" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0417275 0"
        />
        <feBlend
          mode="normal"
          in2="effect3_dropShadow_5626_22391"
          result="effect4_dropShadow_5626_22391"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="41.7776" />
        <feGaussianBlur stdDeviation="16.711" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0503198 0"
        />
        <feBlend
          mode="normal"
          in2="effect4_dropShadow_5626_22391"
          result="effect5_dropShadow_5626_22391"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="100" />
        <feGaussianBlur stdDeviation="40" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"
        />
        <feBlend
          mode="normal"
          in2="effect5_dropShadow_5626_22391"
          result="effect6_dropShadow_5626_22391"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect6_dropShadow_5626_22391"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_i_5626_22391"
        x="133.684"
        y="65.3093"
        width="146.184"
        height="150.184"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="7.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_5626_22391"
        />
      </filter>
      <clipPath id="clip0_5626_22391">
        <rect
          width="146.184"
          height="146.184"
          fill="white"
          transform="translate(133.684 65.3093)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script setup>
const props = defineProps({
  backgroundClass: {
    type: String,
    required: false,
    default: "text-[#1F1F1F] group-hover:text-white",
  },
  iconClass: {
    type: String,
    required: false,
    default: "text-white group-hover:text-primary",
  },
});
</script>
