<template>
  <svg
    class="group"
    width="436"
    height="456"
    viewBox="0 0 400 300"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g :class="props.backgroundClass" filter="url(#filter0_dddddd_5626_22411)">
      <path
        d="M80.3555 57.1777C80.3555 25.6975 105.875 0.177734 137.355 0.177734H298.355C329.836 0.177734 355.355 25.6975 355.355 57.1777V218.178C355.355 249.658 329.836 275.178 298.355 275.178H137.355C105.875 275.178 80.3555 249.658 80.3555 218.178V57.1777Z"
        fill="currentColor"
      />
      <path
        d="M80.8555 57.1777C80.8555 25.9736 106.151 0.677734 137.355 0.677734H298.355C329.56 0.677734 354.855 25.9736 354.855 57.1777V218.178C354.855 249.382 329.56 274.678 298.355 274.678H137.355C106.151 274.678 80.8555 249.382 80.8555 218.178V57.1777Z"
        stroke="white"
        stroke-opacity="0.3"
      />
    </g>
    <g :class="props.iconClass" filter="url(#filter1_i_5626_22411)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M151.578 82.5957C151.578 79.6265 152.758 76.7789 154.857 74.6794C156.957 72.5799 159.804 71.4004 162.773 71.4004H274.373C275.844 71.398 277.302 71.6858 278.662 72.2475C280.022 72.8091 281.258 73.6334 282.299 74.6733C283.34 75.7132 284.166 76.9482 284.729 78.3077C285.292 79.6671 285.581 81.1242 285.58 82.5957V194.195C285.582 195.667 285.293 197.124 284.731 198.485C284.169 199.845 283.344 201.08 282.303 202.121C281.263 203.162 280.028 203.988 278.668 204.551C277.308 205.114 275.851 205.403 274.379 205.403H162.773C161.303 205.403 159.846 205.113 158.488 204.55C157.129 203.987 155.895 203.162 154.855 202.121C153.815 201.081 152.991 199.846 152.429 198.487C151.866 197.128 151.577 195.672 151.578 194.201V82.5957ZM204.619 122.492H222.764V131.604C225.383 126.366 232.083 121.651 242.151 121.651C261.454 121.651 266.028 132.085 266.028 151.229V186.691H246.494V155.59C246.494 144.687 243.875 138.536 237.224 138.536C227.996 138.536 224.159 145.169 224.159 155.59V186.691H204.619V122.492ZM171.118 185.857H190.658V121.651H171.118V185.85V185.857ZM193.454 100.71C193.491 102.383 193.193 104.047 192.578 105.603C191.963 107.16 191.044 108.578 189.874 109.774C188.704 110.97 187.306 111.921 185.764 112.57C184.221 113.218 182.565 113.553 180.891 113.553C179.218 113.553 177.561 113.218 176.019 112.57C174.476 111.921 173.079 110.97 171.909 109.774C170.738 108.578 169.819 107.16 169.204 105.603C168.589 104.047 168.292 102.383 168.328 100.71C168.401 97.4264 169.756 94.3013 172.104 92.0044C174.452 89.7074 177.606 88.4212 180.891 88.4212C184.176 88.4212 187.33 89.7074 189.678 92.0044C192.026 94.3013 193.382 97.4264 193.454 100.71Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <filter
        id="filter0_dddddd_5626_22411"
        x="0.355469"
        y="0.177734"
        width="435"
        height="455"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2.76726" />
        <feGaussianBlur stdDeviation="1.1069" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0196802 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5626_22411"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="6.6501" />
        <feGaussianBlur stdDeviation="2.66004" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0282725 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_5626_22411"
          result="effect2_dropShadow_5626_22411"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="12.5216" />
        <feGaussianBlur stdDeviation="5.00862" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.035 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_dropShadow_5626_22411"
          result="effect3_dropShadow_5626_22411"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="22.3363" />
        <feGaussianBlur stdDeviation="8.93452" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0417275 0"
        />
        <feBlend
          mode="normal"
          in2="effect3_dropShadow_5626_22411"
          result="effect4_dropShadow_5626_22411"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="41.7776" />
        <feGaussianBlur stdDeviation="16.711" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0503198 0"
        />
        <feBlend
          mode="normal"
          in2="effect4_dropShadow_5626_22411"
          result="effect5_dropShadow_5626_22411"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="100" />
        <feGaussianBlur stdDeviation="40" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"
        />
        <feBlend
          mode="normal"
          in2="effect5_dropShadow_5626_22411"
          result="effect6_dropShadow_5626_22411"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect6_dropShadow_5626_22411"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_i_5626_22411"
        x="145.486"
        y="65.3093"
        width="146.184"
        height="150.184"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="7.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_5626_22411"
        />
      </filter>
    </defs>
  </svg>
</template>
<script setup>
const props = defineProps({
  backgroundClass: {
    type: String,
    required: false,
    default: "text-[#1F1F1F] group-hover:text-white",
  },
  iconClass: {
    type: String,
    required: false,
    default: "text-white group-hover:text-primary",
  },
});
</script>
