<template>
  <svg
    width="100"
    height="47"
    viewBox="0 0 100 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M15.9765 20.4291L15.6807 20.7251H13.6096L13.0179 20.4291L6.50895 12.4351H6.21309L4.14206 14.5076V20.4291L3.8462 20.7251H0.295861L0 20.4291V0.296073L0.295861 0H3.8462L4.14206 0.296073V8.8822H4.43792L13.0179 0.296073L13.6096 0H15.0889L15.3848 0.296073V3.25681L9.46757 9.17827V9.47435L15.9765 17.4683V20.4291Z" fill="currentColor" />
    <path d="M30.7511 13.3233V14.5076L30.4552 14.8037H21.2835L20.9877 15.0997C20.9877 15.5735 21.2244 16.0867 21.6977 16.6393C22.1711 17.192 22.9206 17.4683 23.9463 17.4683C25.0311 17.4683 25.7215 17.1723 26.0173 16.5801L26.3132 16.284H29.8635L30.1594 16.5801C30.0805 17.0736 29.9128 17.5572 29.6564 18.0309C29.4197 18.5046 29.0745 18.9783 28.6209 19.452C28.1672 19.9257 27.5361 20.3106 26.7274 20.6067C25.9187 20.883 24.9917 21.0212 23.9463 21.0212C21.5597 21.0212 19.7253 20.2909 18.4433 18.8303C17.1809 17.3696 16.5497 15.534 16.5497 13.3233C16.5497 11.1324 17.1612 9.30657 18.3841 7.84594C19.6267 6.36558 21.3822 5.62539 23.6504 5.62539C25.9187 5.62539 27.6643 6.36558 28.8872 7.84594C30.1298 9.30657 30.7511 11.1324 30.7511 13.3233ZM26.0173 11.8429L26.3132 11.5469C26.3132 11.4284 26.2836 11.2607 26.2244 11.0435C26.1652 10.8264 26.0469 10.5698 25.8694 10.2737C25.7116 9.95793 25.4355 9.70134 25.041 9.50395C24.6662 9.28683 24.2027 9.17827 23.6504 9.17827C23.0981 9.17827 22.6248 9.28683 22.2303 9.50395C21.8555 9.70134 21.5794 9.95793 21.4019 10.2737C21.2441 10.5698 21.1356 10.8264 21.0764 11.0435C21.0173 11.2607 20.9877 11.4284 20.9877 11.5469L21.2835 11.8429H26.0173Z" fill="currentColor" />
    <path d="M46.7288 13.3233V14.5076L46.4329 14.8037H37.2612L36.9653 15.0997C36.9653 15.5735 37.202 16.0867 37.6754 16.6393C38.1488 17.192 38.8983 17.4683 39.924 17.4683C41.0088 17.4683 41.6991 17.1723 41.995 16.5801L42.2908 16.284H45.8412L46.137 16.5801C46.0581 17.0736 45.8905 17.5572 45.6341 18.0309C45.3974 18.5046 45.0522 18.9783 44.5986 19.452C44.1449 19.9257 43.5137 20.3106 42.7051 20.6067C41.8964 20.883 40.9693 21.0212 39.924 21.0212C37.5373 21.0212 35.703 20.2909 34.4209 18.8303C33.1586 17.3696 32.5274 15.534 32.5274 13.3233C32.5274 11.1324 33.1389 9.30657 34.3618 7.84594C35.6044 6.36558 37.3598 5.62539 39.6281 5.62539C41.8964 5.62539 43.6419 6.36558 44.8648 7.84594C46.1075 9.30657 46.7288 11.1324 46.7288 13.3233ZM41.995 11.8429L42.2908 11.5469C42.2908 11.4284 42.2613 11.2607 42.2021 11.0435C42.1429 10.8264 42.0246 10.5698 41.8471 10.2737C41.6893 9.95793 41.4131 9.70134 41.0186 9.50395C40.6439 9.28683 40.1804 9.17827 39.6281 9.17827C39.0758 9.17827 38.6024 9.28683 38.208 9.50395C37.8332 9.70134 37.5571 9.95793 37.3795 10.2737C37.2218 10.5698 37.1133 10.8264 37.0541 11.0435C36.9949 11.2607 36.9653 11.4284 36.9653 11.5469L37.2612 11.8429H41.995Z" fill="currentColor" />
    <path d="M69.5182 9.17827L65.9678 20.4291L65.672 20.7251H61.8258L61.5299 20.4291L59.163 11.5469H58.8672L56.5003 20.4291L56.2044 20.7251H52.3582L52.0624 20.4291L48.512 9.17827V6.21754L48.8079 5.92147H51.4706L51.7665 6.21754L54.1334 14.5076H54.4293L56.7961 6.21754L57.092 5.92147H60.9382L61.2341 6.21754L63.601 14.5076H63.8968L66.2637 6.21754L66.5596 5.92147H69.2223L69.5182 6.21754V9.17827Z" fill="currentColor" />
    <path d="M84.3193 20.4291L84.0235 20.7251H80.769L80.4731 20.4291L80.1773 19.8369H79.8814C79.8025 19.9159 79.6743 20.0244 79.4968 20.1626C79.3193 20.3008 78.9445 20.4784 78.3725 20.6955C77.8203 20.9126 77.2384 21.0212 76.6269 21.0212C74.9898 21.0212 73.6881 20.6067 72.7216 19.7777C71.7748 18.929 71.3014 17.7644 71.3014 16.284C71.3014 15.4353 71.4494 14.6951 71.7452 14.0635C72.0608 13.4319 72.4454 12.9483 72.8991 12.6127C73.3527 12.2574 73.8853 11.9712 74.4967 11.7541C75.1279 11.537 75.6999 11.3988 76.2127 11.3396C76.7256 11.2804 77.2581 11.2508 77.8104 11.2508C78.1654 11.2508 78.5106 11.2804 78.8459 11.3396C79.2009 11.3791 79.4672 11.4284 79.6447 11.4876L79.8814 11.5469L80.1773 11.2508V10.9547C80.1773 10.2047 80.0491 9.72107 79.7927 9.50395C79.556 9.28683 78.9938 9.17827 78.1063 9.17827C77.7118 9.17827 77.4061 9.18814 77.1891 9.20788C76.9918 9.22762 76.7749 9.30657 76.5382 9.44474C76.3015 9.58291 76.1338 9.79016 76.0352 10.0665L75.7394 10.3626H72.189L71.8932 10.0665C71.8932 8.96115 72.3961 7.94463 73.4021 7.01694C74.408 6.08924 75.9761 5.62539 78.1063 5.62539C80.3943 5.62539 82.0018 6.17806 82.9288 7.2834C83.8558 8.38874 84.3193 10.106 84.3193 12.4351V20.4291ZM80.1773 14.8037L79.8814 14.5076C79.4869 14.3102 78.8952 14.2115 78.1063 14.2115C76.3311 14.2115 75.4435 14.8037 75.4435 15.988C75.4435 16.9749 76.1338 17.4683 77.5145 17.4683C78.264 17.4683 78.8952 17.2709 79.408 16.8762C79.9209 16.4814 80.1773 15.988 80.1773 15.3958V14.8037Z" fill="currentColor" />
    <path d="M100 9.17827L95.2662 21.0212C94.9309 21.8502 94.6153 22.5213 94.3195 23.0345C94.0236 23.5477 93.6291 24.0313 93.136 24.4853C92.6429 24.9392 92.0413 25.2649 91.3313 25.4623C90.6212 25.6597 89.7632 25.7584 88.7573 25.7584H87.278L86.9821 25.4623V22.5016L87.278 22.2055H88.7573C89.4673 22.2055 89.9506 22.1265 90.207 21.9686C90.4634 21.8107 90.6705 21.4949 90.8283 21.0212V20.7251L86.0945 9.17827V6.21754L86.3904 5.92147H89.0531L89.349 6.21754L92.8993 16.284H93.1952L96.7455 6.21754L97.0414 5.92147H99.7041L100 6.21754V9.17827Z" fill="currentColor" />
    <path d="M41.4224 46.0351C39.5548 46.0351 38.0028 45.4167 36.7665 44.18C35.5303 42.9432 34.9121 41.1933 34.9121 38.9303C34.9121 36.6673 35.5303 34.9174 36.7665 33.6806C38.0028 32.4438 39.5548 31.8254 41.4224 31.8254C42.9874 31.8254 44.2106 32.2202 45.0918 33.0096C45.973 33.7858 46.5253 34.7726 46.7489 35.9699L46.5516 36.1673H45.7625L45.5652 35.9699C45.0918 33.8648 43.7108 32.8122 41.4224 32.8122C39.7784 32.8122 38.4763 33.378 37.5162 34.5095C36.5693 35.641 36.0958 37.1146 36.0958 38.9303C36.0958 40.746 36.5693 42.2196 37.5162 43.3511C38.4763 44.4826 39.7784 45.0483 41.4224 45.0483C42.2772 45.0483 42.9874 44.9168 43.553 44.6536C44.1317 44.3905 44.5591 44.0155 44.8353 43.5287C45.1115 43.0287 45.3022 42.5156 45.4074 41.9893C45.5126 41.463 45.5652 40.8381 45.5652 40.1144L45.368 39.9171H40.8305L40.6332 39.7197V39.1276L40.8305 38.9303H46.5516L46.7489 39.1276V45.6404L46.5516 45.8378H46.1571L45.9598 45.6404L45.7625 44.0616H45.5652C44.6841 45.3773 43.3031 46.0351 41.4224 46.0351Z" fill="currentColor" />
    <path d="M54.644 39.7197L54.2494 39.5224H50.8957L50.6984 39.7197V45.6404L50.5011 45.8378H49.712L49.5147 45.6404V32.2202L49.712 32.0228H55.4331C56.9193 32.0228 58.0109 32.332 58.708 32.9504C59.4182 33.5556 59.7733 34.4963 59.7733 35.7726C59.7733 36.3778 59.6812 36.9107 59.4971 37.3712C59.3261 37.8317 59.1091 38.1935 58.8461 38.4566C58.5962 38.7198 58.2937 38.9369 57.9386 39.1079C57.5835 39.2789 57.2547 39.3908 56.9522 39.4434C56.6497 39.496 56.3406 39.5224 56.0249 39.5224V39.7197L59.3787 45.0483V45.6404L59.1814 45.8378H58.7869L58.3923 45.6404L54.644 39.7197ZM57.8005 37.9238C58.3265 37.5159 58.5896 36.7988 58.5896 35.7726C58.5896 34.7463 58.3331 34.0293 57.8202 33.6214C57.3204 33.2135 56.5905 33.0096 55.6304 33.0096H50.8957L50.6984 33.2069V38.3382L50.8957 38.5356H55.4331C56.4853 38.5356 57.2744 38.3316 57.8005 37.9238Z" fill="currentColor" />
    <path d="M70.0644 45.6207C69.3147 45.897 68.5124 46.0351 67.6575 46.0351C66.8027 46.0351 66.0004 45.897 65.2507 45.6207C64.5011 45.3312 63.8303 44.9102 63.2385 44.3576C62.6598 43.7918 62.1995 43.0485 61.8575 42.1275C61.5155 41.1933 61.3446 40.1276 61.3446 38.9303C61.3446 37.733 61.5155 36.6738 61.8575 35.7528C62.1995 34.8187 62.6598 34.0753 63.2385 33.5227C63.8303 32.957 64.5011 32.5359 65.2507 32.2596C66.0004 31.9702 66.8027 31.8254 67.6575 31.8254C68.5124 31.8254 69.3147 31.9702 70.0644 32.2596C70.814 32.5359 71.4782 32.957 72.0569 33.5227C72.6487 34.0753 73.1156 34.8187 73.4576 35.7528C73.7995 36.6738 73.9705 37.733 73.9705 38.9303C73.9705 40.1276 73.7995 41.1933 73.4576 42.1275C73.1156 43.0485 72.6487 43.7918 72.0569 44.3576C71.4782 44.9102 70.814 45.3312 70.0644 45.6207ZM71.3664 34.3713C70.4326 33.3319 69.1963 32.8122 67.6575 32.8122C66.1188 32.8122 64.8759 33.3319 63.9289 34.3713C62.9951 35.4108 62.5283 36.9304 62.5283 38.9303C62.5283 40.9302 62.9951 42.4498 63.9289 43.4892C64.8759 44.5286 66.1188 45.0483 67.6575 45.0483C69.1963 45.0483 70.4326 44.5286 71.3664 43.4892C72.3133 42.4498 72.7868 40.9302 72.7868 38.9303C72.7868 36.9304 72.3133 35.4108 71.3664 34.3713Z" fill="currentColor" />
    <path d="M86.591 32.2202V40.9038C86.591 42.4827 86.1307 43.7326 85.2101 44.6536C84.2894 45.5746 83.04 46.0351 81.4618 46.0351C79.8835 46.0351 78.6341 45.5746 77.7134 44.6536C76.7928 43.7326 76.3325 42.4827 76.3325 40.9038V32.2202L76.5298 32.0228H77.3189L77.5162 32.2202V40.9038C77.5162 42.1801 77.8713 43.1932 78.5815 43.9431C79.3048 44.6799 80.2649 45.0483 81.4618 45.0483C82.7243 45.0483 83.6976 44.6865 84.3815 43.9629C85.0654 43.2261 85.4074 42.2064 85.4074 40.9038V32.2202L85.6046 32.0228H86.3938L86.591 32.2202Z" fill="currentColor" />
    <path d="M98.9346 32.9504C99.6448 33.5556 99.9999 34.4963 99.9999 35.7726C99.9999 37.0488 99.6448 37.9961 98.9346 38.6145C98.2376 39.2197 97.1459 39.5224 95.6598 39.5224H91.1223L90.925 39.7197V45.6404L90.7278 45.8378H89.9386L89.7414 45.6404V32.2202L89.9386 32.0228H95.6598C97.1459 32.0228 98.2376 32.332 98.9346 32.9504ZM98.0271 37.9238C98.5532 37.5159 98.8162 36.7988 98.8162 35.7726C98.8162 34.7463 98.5598 34.0293 98.0469 33.6214C97.5471 33.2135 96.8171 33.0096 95.857 33.0096H91.1223L90.925 33.2069V38.3382L91.1223 38.5356H95.6598C96.7119 38.5356 97.501 38.3316 98.0271 37.9238Z" fill="currentColor" />
  </svg>
</template>
