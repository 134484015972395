<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34"
    height="34"
    viewBox="0 0 26 28"
    fill="none"
  >
    <g clip-path="url(#clip0_4627_854)">
      <path
        d="M13 24.8334C7.01674 24.8334 2.16666 19.9833 2.16666 14.0001C2.16666 8.01683 7.01674 3.16675 13 3.16675C18.9832 3.16675 23.8333 8.01683 23.8333 14.0001C23.8333 19.9833 18.9832 24.8334 13 24.8334ZM10.5192 22.306C9.45036 20.0389 8.83118 17.5861 8.69591 15.0834H4.40049C4.61129 16.7506 5.30174 18.3206 6.38794 19.6028C7.47414 20.8849 8.90934 21.824 10.5192 22.306ZM10.8658 15.0834C11.0294 17.7257 11.7845 20.2076 13 22.3981C14.2483 20.1497 14.9776 17.6503 15.1342 15.0834H10.8658ZM21.5995 15.0834H17.3041C17.1688 17.5861 16.5496 20.0389 15.4808 22.306C17.0906 21.824 18.5258 20.8849 19.612 19.6028C20.6982 18.3206 21.3887 16.7506 21.5995 15.0834ZM4.40049 12.9167H8.69591C8.83118 10.414 9.45036 7.96124 10.5192 5.69416C8.90934 6.17612 7.47414 7.11523 6.38794 8.3974C5.30174 9.67957 4.61129 11.2496 4.40049 12.9167ZM10.8669 12.9167H15.1331C14.9768 10.3499 14.248 7.85057 13 5.60208C11.7516 7.85048 11.0224 10.3498 10.8658 12.9167H10.8669ZM15.4808 5.69416C16.5496 7.96124 17.1688 10.414 17.3041 12.9167H21.5995C21.3887 11.2496 20.6982 9.67957 19.612 8.3974C18.5258 7.11523 17.0906 6.17612 15.4808 5.69416Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_4627_854">
        <rect width="34" height="34" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

