<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="99"
    height="28"
    viewBox="0 0 99 28"
    fill="none"
  >
    <path d="M86.3435 0L72.2652 27.8977H0L14.0782 0H86.3435Z" fill="#5ACCF0" />
    <path
      d="M98.6177 0L84.5559 27.8977H76.8574L90.9192 0H98.6177Z"
      fill="#FF212A"
    />
  </svg>
</template>
